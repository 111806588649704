<template>
  <p v-capture-scroll="closeDatePicker" class="d-flex align-items-center gap-2 mt-2 mb-2">
    <span v-if="datePickerLabel">
      {{ datePickerLabel }}
    </span>
    <el-date-picker
      v-if="showDatePicker"
      :key="datePickerKey"
      v-model="dateFilterRef"
      :type="datePickerType"
      :format="textFormat"
      :clearable="clearable"
      :placeholder="datePickerPlaceholder"
      :picker-options="pickerOptions"
      class="picker-small"
      size="small"
      @input="$emit('on-date-picker-date-change', $event)"
    />
  </p>
</template>
<script>
import { getCurrentInstance, ref } from 'vue';
import { useTranslate } from '@/modules/core/compositions/translate';
import { getISOMonth } from '@/modules/activity/components/orderDifferenceMatchModal/utils';
import { captureScroll } from '@/directives/capture-scroll';

export default {
  directives: {
    captureScroll,
  },
  props: {
    datePickerPlaceholder: { type: String, default: null },
    datePickerLabel: { type: String, default: null },
    dateFilter: { type: [String, Date], default: null },
    datePickerType: { type: String, default: 'month' },
    showDatePickerShortcutOptions: { type: Boolean, default: false },
    clearable: { type: Boolean, default: false },
  },
  emits: ['on-month-picker-date-change'],
  setup(props) {
    const $t = useTranslate();
    const dateFilterRef = ref(props.dateFilter);
    const textFormat = ref(props.datePickerType === 'month' ? 'MMMM yyyy' : 'yyyy');
    const showDatePicker = ref(false);
    const datePickerKey = ref(0);
    const root = getCurrentInstance().proxy;

    const createShortcuts = () => {
      return [
        {
          text: $t('bills.currentMonth'),
          onClick: (vm) => {
            vm.$emit('pick', getISOMonth(new Date()));
          },
        },
        {
          text: $t('bills.previousMonth'),
          onClick: (vm) => {
            const date = new Date();
            date.setMonth(date.getMonth() - 1);
            vm.$emit('pick', getISOMonth(date));
          },
        },
      ];
    };

    const shortcuts = props.showDatePickerShortcutOptions ? createShortcuts() : null;

    const closeDatePicker = () => {
      showDatePicker.value = false;
      root.$nextTick(() => {
        datePickerKey.value += 1;
        showDatePicker.value = true;
      });
    };

    return {
      datePickerKey,
      showDatePicker,
      pickerOptions: { shortcuts },
      dateFilterRef,
      textFormat,
      closeDatePicker,
    };
  },
};
</script>
<style lang="scss" scoped>
.picker-small {
  width: 136px;
}
</style>
