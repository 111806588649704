<template>
  <div v-capture-scroll="handleScroll" class="selects-container">
    <el-select
      ref="firstSelect"
      :value="listOneValue"
      size="small"
      @change="handleChange('listOneValue', $event)"
      @visible-change="(visible) => (firstSelectOpen = visible)"
    >
      <el-option v-for="option in selectOption" :key="option.value" :label="option.label" :value="option.value" />
    </el-select>

    <el-select
      ref="secondSelect"
      :value="listTwoValue"
      :class="$t('direction') === 'rtl' ? 'me-1' : 'ms-1'"
      :disabled="!availableCompareOptions.length"
      size="small"
      @change="handleChange('listTwoValue', $event)"
      @visible-change="(visible) => (secondSelectOpen = visible)"
      ><el-option
        v-for="compareOption in availableCompareOptions"
        :key="compareOption.value"
        :label="compareOption.label"
        :value="compareOption.value"
      />
    </el-select>
  </div>
</template>
<script>
import { computed, ref } from 'vue';

import {
  getPeriodOptions,
  getAvailableCompareOptions,
  convertPeriodOptionToSelectOption,
} from '@/modules/order/components/purchaseData/tools/utils';
import { PERIOD_OPTIONS } from '@/modules/order/components/purchaseData/tools/constants';
import { captureScroll } from '@/directives/capture-scroll';

export default {
  directives: {
    captureScroll,
  },
  props: {
    listOne: { type: Array, default: null }, // [ {value, label}, {value, label} ]
    listTwo: { type: Array, default: null }, // [ {value, label}, {value, label} ]
    shouldCloseSelectsOnScroll: { type: Boolean, default: false },
  },
  setup(props, ctx) {
    const { emit } = ctx;
    const listOneValue = ref(PERIOD_OPTIONS.CURRENT_MONTH);
    const listTwoValue = ref(null);
    const firstSelect = ref(null);
    const firstSelectOpen = ref(false);
    const secondSelect = ref(null);
    const secondSelectOpen = ref(false);

    const availableCompareOptions = computed(
      () => props.listOne || getAvailableCompareOptions(listOneValue.value).map(convertPeriodOptionToSelectOption)
    );
    const selectOption = computed(() => props.listTwo || getPeriodOptions(convertPeriodOptionToSelectOption));

    const handleChange = (type, event) => {
      if (type === 'listOneValue') {
        listOneValue.value = event;
        listTwoValue.value = null;
      }
      if (type === 'listTwoValue') {
        listTwoValue.value = event;
      }

      emit('on-two-select-list-update', { listOneValue, listTwoValue });
    };

    const handleScroll = () => {
      if (props.shouldCloseSelectsOnScroll && firstSelectOpen.value && firstSelect.value) {
        firstSelect.value.blur();
      }
      if (props.shouldCloseSelectsOnScroll && secondSelectOpen.value && secondSelect.value) {
        secondSelect.value.blur();
      }
    };

    return {
      firstSelect,
      secondSelect,
      firstSelectOpen,
      secondSelectOpen,
      listOneValue,
      listTwoValue,
      availableCompareOptions,
      selectOption,
      handleScroll,
      handleChange,
    };
  },
};
</script>
<style scoped lang="scss">
.selects-container {
  display: grid;
  grid-template-columns: 120px 120px;
  gap: 8px;
}
</style>
