<template>
  <div class="border-bottom-0">
    <div class="period-button-container">
      <el-button type="text" icon="el-icon-arrow-left" @click="() => dateChange(false)" />
      <span :dir="$direction" class="period-text">{{ buttonText }}</span>
      <el-button type="text" icon="el-icon-arrow-right" :disabled="nextDisabled" @click="() => dateChange(true)" />
    </div>
  </div>
</template>
<script>
import { computed, ref, getCurrentInstance } from 'vue';
import { DateTime } from 'luxon';

const getClosestStepMonth = (endOfMonth, steps) => {
  const currentMonth = DateTime.local().month;
  const closestStepMonth = Math.ceil(currentMonth / steps) * steps;
  const closestStepDate = DateTime.local().set({ month: closestStepMonth });

  return endOfMonth ? closestStepDate.endOf('month') : closestStepDate.startOf('month');
};

export default {
  props: {
    steps: { type: Number, default: 6 },
    fromDate: { type: DateTime, default: null },
    toDate: { type: DateTime, default: null },
  },
  setup(props, { emit }) {
    const root = getCurrentInstance().proxy;

    const toDate = ref(props.toDate || getClosestStepMonth(false, props.steps));
    const fromDate = computed(() => props.fromDate || toDate.value.minus({ months: 5 }));

    const nextDisabled = computed(() => toDate.value > DateTime.local());

    const dateChange = (isNext = false) => {
      if (isNext) {
        toDate.value = toDate.value.plus({ months: props.steps });
      }
      if (!isNext) {
        toDate.value = toDate.value.minus({ months: props.steps });
      }
      emit('on-range-updated', { toDate, fromDate });
    };

    const isLTR = root.$i18n.t('direction') === 'ltr';

    const buttonText = computed(() =>
      +toDate.value === +getClosestStepMonth(true, props.steps)
        ? root.$i18n.t('components.FromMonthToMonthStepRangeInput.currentHalfYear')
        : `${(isLTR ? fromDate : toDate).value.toJSDate().toLocaleDateString(root.$i18n.locale, {
            month: '2-digit',
            year: '2-digit',
          })} - ${(root.$i18n.t('direction') === 'ltr' ? toDate : fromDate).value
            ?.toJSDate()
            .toLocaleDateString(root.$i18n.locale, { month: '2-digit', year: '2-digit' })}`
    );

    return {
      nextDisabled,
      buttonText,
      dateChange,
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.period-button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid $outline;
  border-radius: 3px;
  padding: 0.5rem 0.625rem;
  height: 2rem;
  min-width: 8.5rem;

  ::v-deep .el-button {
    padding: 0;
    border: none;

    &:enabled {
      color: #1f284d;
    }
  }

  .period-text {
    font-size: 12px;
    padding: 0 0.625rem;
  }
}
</style>
